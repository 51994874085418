import React, { useState, useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import CustomAudioPlayer from './CustomAudioPlayer';
import './MediaPlayer.css';

// Import all audio and image files here
import audio0 from './music/Close To You.mp3';
import audio1 from './music/China Girl ft. SnowFlake Black.mp3';
import audio2 from './music/Incantations of Melody (An Autumn Song).mp3';
import audio4 from './music/1716006618638-Beneath the Falls.mp3';
import audio5 from './music/Foolish Games.mp3';
import audio6 from './music/The Other Side of the Mountain.mp3';
import audio7 from './music/All That I Waited, More (기다린 만큼, 더).mp3';
import audio8 from './music/My Army.mp3';
import audio9 from './music/Not To Worry - Master.mp3';
import audio10 from './music/Said and Done.mp3';
import audio11 from './music/The 80s Are Gone (Basic Pro Master 128kbps).mp3';
import audio12 from './music/Wanna Love Ya.mp3';

import img0 from './music/img/Close_to_You.jpg';
import img1 from './music/img/China_Girl.jpg';
import img2 from './music/img/Incantations_of_Melody.jpg';
import img4 from './music/img/Beneath_The_Falls.jpg';
import img5 from './music/img/Foolish_Games.jpg';
import img6 from './music/img/manifests/The_Other_Side_of_the_Mountain.jpg';
import img7 from './music/img/All_That_I_Waited_More.jpg';
import img8 from './music/img/My_Army.jpg';
import img9 from './music/img/Not_to_Worry.webp';
import img10 from './music/img/Said_and_Done.jpg';
import img11 from './music/img/The_80s_are_Gone.jpg';
import img12 from './music/img/Wanna_Love_Ya.jpg';

const MediaPlayer = () => {
    const [currentTrackIndex, setCurrentTrackIndex] = useState(0);
    const [cart, setCart] = useState([]); // Cart state
    const navigate = useNavigate();

    const tracks = useMemo(() => [
        { src: audio0, title: 'Close To You by Lord Toph', name: 'Close To You.mp3', img: img0, description: '<div class="song-description">Lord Toph brings a romantic ballad with "Close To You". Let the heartfelt lyrics and melody take you away.</div>', price: '1.00' },
        { src: audio12, title: 'Wanna Love Ya', name: 'Wanna_Love_Ya.mp3', img: img12, description: '<div class="song-description">"Wanna Love Ya" is an upbeat and lively track that expresses the joy and excitement of new love.</div>', price: '1.00' },
        { src: audio6, title: 'The Other Side of the Mountain', name: 'The Other Side of the Mountain.mp3', img: img6, description: '<div class="song-description">This is a soothing track that takes you to the other side of the mountain. Enjoy the serene and peaceful melodies.</div>', price: '1.00' },
        { src: audio1, title: 'China Girl by Lord Toph ft. SnowFlake Black', name: 'China Girl ft. SnowFlake Black.mp3', img: img1, description: '<div class="song-description">A collaboration between Lord Toph and SnowFlake Black, "China Girl" is a unique blend of styles and cultures.</div>', price: '1.00' },
        { src: audio2, title: 'Incantations of Melody (An Autumn Song) by Lord Toph', name: 'Incantations of Melody (An Autumn Song).mp3', img: img2, description: '<div class="song-description">"Incantations of Melody" is an autumn-themed song that captures the essence of the season with its enchanting tunes.</div>', price: '1.00' },
        { src: audio4, title: 'Beneath the Falls by Lord Toph', name: 'Beneath the Falls.mp3', img: img4, description: '<div class="song-description">Experience the power and beauty of nature with "Beneath the Falls" by Lord Toph. A truly immersive track.</div>', price: '1.00' },
        { src: audio5, title: 'Foolish Games by Lord Toph', name: 'Foolish Games.mp3', img: img5, description: '<div class="song-description">"Foolish Games" is a captivating track by Lord Toph that explores themes of love and loss.</div>', price: '1.00' },
        { src: audio7, title: 'All That I Waited, More (기다린 만큼, 더)', name: 'All_That_I_Waited_More.mp3', img: img7, description: '<div class="song-description">"All That I Waited, More (기다린 만큼, 더)" is a soulful song that delves into the depths of longing and fulfillment.</div>', price: '1.00' },
        { src: audio8, title: 'My Army', name: 'My_Army.mp3', img: img8, description: '<div class="song-description">"My Army" is a powerful anthem that speaks to the strength and unity of a determined group.</div>', price: '1.00' },
        { src: audio9, title: 'Not To Worry', name: 'Not_To_Worry.mp3', img: img9, description: '<div class="song-description">"Not To Worry" is a comforting track that reassures and soothes with its gentle melody.</div>', price: '1.00' },
        { src: audio10, title: 'Said and Done', name: 'Said_and_Done.mp3', img: img10, description: '<div class="song-description">"Said and Done" reflects on past actions and their consequences with a poignant melody.</div>', price: '1.00' },
        { src: audio11, title: 'The 80s Are Gone', name: 'The_80s_Are_Gone.mp3', img: img11, description: '<div class="song-description">"The 80s Are Gone" is a nostalgic tribute to a bygone era, filled with memories and emotions.</div>', price: '1.00' }
    ], []);

    const nextTrack = () => {
        setCurrentTrackIndex((prevIndex) => (prevIndex + 1) % tracks.length);
    };

    const previousTrack = () => {
        setCurrentTrackIndex((prevIndex) => (prevIndex - 1 + tracks.length) % tracks.length);
    };

    const setTrack = (index) => {
        setCurrentTrackIndex(index);
    };

    const handleAddToCart = (index) => {
        setCart(prevCart => [...prevCart, tracks[index]]);
        console.log(`Track added to cart: ${tracks[index].title}`);
    };

    const handleCheckout = () => {
        if (cart.length > 0) {
            navigate('/checkout', { state: { cart } });
        }
    };

    return (
        <div className="media-player">
            <h2>Now Playing: {tracks[currentTrackIndex].title}</h2>
            <div dangerouslySetInnerHTML={{ __html: tracks[currentTrackIndex].description }} />
            <Zoom>
                <img className="musicimage" src={tracks[currentTrackIndex].img} alt={tracks[currentTrackIndex].title} />
            </Zoom>
            <CustomAudioPlayer
                src={tracks[currentTrackIndex].src}
                onEnded={nextTrack}
                duration={40000} // 40 seconds
                onPrev={previousTrack}
                onNext={nextTrack}
            />
            <div className="thumbnail-navigation">
                {tracks.map((track, index) => (
                    <img
                        key={index}
                        src={track.img}
                        alt={track.title}
                        title={track.title}
                        className={`thumbnail ${index === currentTrackIndex ? 'active' : ''}`}
                        onClick={() => setTrack(index)}
                        style={{ cursor: 'pointer' }}
                    />
                ))}
            </div>
            <div>Download Full Song: ${tracks[currentTrackIndex].price} USD</div>
            <button onClick={() => handleAddToCart(currentTrackIndex)}>Add to Cart</button>
            <h3>Cart</h3>
            <div className="cart">
                <ul>
                    {cart.map((track, index) => (
                        <li key={index}>{track.title} - ${track.price} USD</li>
                    ))}
                </ul>
                {cart.length > 0 && <button className="checkout-btn" onClick={handleCheckout}>Checkout</button>}
            </div>
        </div>
    );
};

export default MediaPlayer;
