// ContactUsPage.js
import React from 'react';
import Hero from './img/LORD_TOPH_by_Pieter_Henket-1.png';

const ContactUsPage = () => (
    <div className={"content"}>
        <img className="hero-img animate__fadeInDownBig" src={Hero} alt={"Lord Toph by Pieter Henket"} />
        <h1>Make Contact With Lord Toph</h1>
        <div className={"contact"}>
        <p>Email: <a href="mailto:montecristoph.com">montecristoph@gmail.com</a></p>

         </div>
    </div>
);


export default ContactUsPage;