// AboutPage.js
import React from 'react';
import LiteratureGallery from "./LiteratureGallery";
const LiteraturePage = () => (
    <div className="content">
<h1>Lord Toph Literature</h1>
        <p>Welcome to the literary sanctuary of Lord Toph, where words weave tales of wonder, mystery and profound introspection. Step into a realm where imagination knows no bounds and storytelling reigns supreme, as you explore Lord Toph's captivating collection of writings, each penned with a touch of magic and a dash of literary flair.</p>
        <LiteratureGallery />    </div>
);

export default LiteraturePage;