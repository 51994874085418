// SocialLinksSection.js
import React from 'react';


const SocialLinksSection = () => (
    <div className={"links"}>
          <ul className={"footer"}>
    <li><a href={"https://spoti.fi/38sj3em"}><img src={"../.././social/spotify.png"} title="Lord Toph on Spotify" alt={"Lord Toph on Spotify"} /></a></li>
    <li><a href={"https://apple.co/37xZoZ6"}><img src={"../.././social/apple.png"} alt={"Lord Toph on Apple Music"} title={"Lord Toph on Apple Music"} /></a></li>
    <li><a href={"https://bit.ly/3hh4UrR"}><img src={"../.././social/patreon.png"} alt={"Lord Toph on Patreon"} title={"Lord Toph on Patreon"}/></a></li>
    <li><a href={"https://amzn.to/2StKdfo"}><img src={"../.././social/amazon.png"} alt={"Lord Toph on Amazon"} title={"Lord Toph on Amazon"}/></a></li>
    <li><a href={"https://bit.ly/2we7dGq"}><img src={"../.././social/x.png"} alt={"Lord Toph on X"} title={"Lord Toph on X"}/></a></li>
    <li><a href={"https://bit.ly/2wmtO3N"}><img src={"../.././social/instagram.png"} alt={"Lord Toph on Instagram"} title={"Lord Toph on Instagram"}/></a></li>
    <li><a href={"https://bit.ly/31X8zkM"}><img src={"../.././social/meta.png"} alt={"Lord Toph on Meta / Facebook"} title={"Lord Toph on Meta / Facebook"}/></a></li>
    <li><a href={"https://www.youtube.com/c/LordToph"}><img src={"../.././social/youtube.png"} alt={"Lord Toph on YouTube"} title={"Lord Toph on YouTube"}/></a></li>
              <li><a href={"https://www.tiktok.com/@lordtoph"}><img src={"../.././social/tiktok.png"} title="Lord Toph on Tiktok" alt={"Lord Toph on Tiktok"} /></a></li>

          </ul>
    </div>
);

export default SocialLinksSection;
