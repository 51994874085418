// HeroSection.js
import React from 'react';
import hero from './img/Psychedelic_Dream_Banner.png';

const PsychedelicDreamPage = () => (
            <div className="content">

                <img src={hero} className={"hero-img"} alt={"Psychedelic Dream"}/>
                <h1>Psychedelic Dream - A Rhythm Rock Odyssey</h1>
                <h2 className={"pink"}>Prepare yourself…</h2>
                <p>To be taken on a fantastic musical journey with "Psychedelic Dream - A Rhythm Rock Odyssey." This
                    groundbreaking production is in the works, spearheaded by the visionary artist Lord Toph. Aiming to
                    redefine the boundaries of musical theater, this project promises to offer a spectacle reminiscent
                    of classic music films like "Tommy," "Sgt. Pepper's Lonely Hearts Club Band," and "Across the
                    Universe."</p>

                <h2 className={"blue"}>What to Expect:</h2>
                <p>Immerse yourself in a Broadway-worthy experience that intertwines colorful highs and lows, depicting
                    the trials and tribulations of Lord Toph's life as a musician, writer, and artist. This unique
                    production promises to be an introspective masterpiece, blending elements of rhythm rock—a new sound
                    that merges offbeat and mainstream influences—with provocative storytelling.</p>

                <h2 className={"orange"}>A Cinematic Musical Journey:</h2>
                <p>Imagine the visual and musical impact of "Tommy" combined with the imaginative storytelling of "Sgt.
                    Pepper's Lonely Hearts Club Band" and the emotional depth of "Across the Universe." "Psychedelic
                    Dream" aims to deliver a level of creativity and artistry that will captivate and enthrall
                    audiences, taking them on a journey through Lord Toph's rich and diverse experiences.</p>

                <h2 className={"green"}>Themes and Energy:</h2>
                <p>"Psychedelic Dream" is unconventional and controversial, sexy and erotic, yet profoundly spiritual.
                    It's a tapestry of emotions and experiences, all brought to life through captivating music, dynamic
                    performances, and stunning visuals. The energy of the show will move you, with each scene
                    meticulously crafted to evoke powerful emotions and thought-provoking reflections.</p>


                <h2 className={"pink"}>A New Tier of Musical:</h2>
                <p>This production is not just a show; it's an odyssey. Lord Toph's "Psychedelic Dream" invites you to
                    explore the depths of his artistic soul, offering a fresh perspective on the musical genre that will
                    leave you eagerly anticipating its premiere. The fusion of rhythmic rock and soul-stirring
                    narratives creates a performance that is both offbeat and mainstream, making it accessible yet
                    profoundly unique.</p>

                <h2 className={"blue"}>An Introspective Masterpiece:</h2>
                <p>Dive into the heart of Lord Toph's life, witnessing his journey from the social politics and
                    prejudice of the Deep South to the relentless and unforgiving atmosphere of New York City. Each
                    moment is a reflection of his growth, passion, ecstasy, tragedy, and tribulation, making
                    "Psychedelic Dream" a deeply personal and universally resonant experience.</p>

                <h2 className={"orange"}>In the Works:</h2>
                <p>Stay tuned as "Psychedelic Dream - A Rhythm Rock Odyssey," created and directed by Lord Toph, is in
                    development, bringing together a team of exceptional talents and creative minds. Be among the first
                    to witness this offbeat, introspective, and unforgettable masterpiece as it comes to life. Join us
                    for an evening that promises to challenge, inspire and entertain in ways you've never experienced
                    before.</p>

                <h2 className={"green"}>Support the Dream:</h2>
                <p>Your support is crucial to bringing "Psychedelic Dream - A Rhythm Rock Odyssey" to life. By
                    contributing, you are not only helping to realize Lord Toph's visionary production but also
                    supporting the vibrant arts community. This production will enhance the cultural landscape and
                    provide an innovative and immersive experience for all who attend. Interested patrons, investors,
                    and sponsors are invited to contact "The Psychedelic Dream Team" at montecristoph@gmail.com.
                    Join us in making history and be a part of a groundbreaking artistic journey. Your contribution will
                    help us create a spectacular event that will leave a lasting impact on the arts community and
                    beyond.</p>

                <p className={"pink"}>For updates and exclusive content, visit lordtoph.com and prepare to be part of a
                    revolutionary musical event, in the making.</p>

            </div>

    );

export default PsychedelicDreamPage;