import React, { useState, useMemo, useEffect } from 'react';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import img0 from './art/A Butterfly\'s Theory.jpg';
import img1 from './art/A Dreadful Nightmare.JPG';
import img2 from './art/Something\'s Missing.JPG';
import img3 from './art/The Magic of Flowers.JPG';
import img4 from './art/We Reap What We Sow.JPG';
import img5 from './art/Yoonsil-On_Canvas_in_Paris.JPG';
import ManifestRetriever from './ManifestRetriever';

const ArtGallery = () => {
    const [currentArtIndex, setCurrentArtIndex] = useState(0);

    useEffect(() => {
        // Apply custom class to the overlay when it appears
        const observer = new MutationObserver(mutations => {
            mutations.forEach(mutation => {
                if (mutation.type === 'childList') {
                    const overlay = document.querySelector('.data-rmiz-overlay');
                    if (overlay) {
                        overlay.classList.add('black-overlay');
                    }
                }
            });
        });

        observer.observe(document.body, { childList: true, subtree: true });

        // Clean up observer on component unmount
        return () => observer.disconnect();
    }, []);

    const artworks = useMemo(() => [
        {
            title: 'A Butterfly\'s Theory',
            img: img0,
            description: '<div className="art-description">This artwork represents the delicate balance of nature through the eyes of a butterfly.</div>',
            name: 'A Butterfly\'s Theory.jpg'
        },
        {
            title: 'A Dreadful Nightmare',
            img: img1,
            description: '<p>The Dreadful Nightmare - An impasto technique (heavy paint application & texture), rich in movement and color, serves to create a whimsicality for this fantastic image of a horse in bed while awakening from a nightmare.</p>\n' +
                '\n' +
                '<p>Balance is created in this painting by the offset positioning of the bedroom window. A surreal nocturnal theme depicting human nature as it responds to fear of the unknown, is clearly stated in this composition.</p>\n' +
                '\n' +
                '<p>The mare has been frightened by unspeakable dreams, and now she wakes only to find a more dreadful vision before her. Terrified by the craning shadow of oddly elongated human hands, the mare becomes far too petrified too move.</p>\n' +
                '<p>The horse is used in this composition to portray how one can easily become alarmed by unfamiliar sounds, movement, and sites; especially during the wee hours of the night.</p>',
            name: 'A Dreadful Nightmare.JPG'
        },
        {
            title: 'Something\'s Missing',
            img: img2,
            description: '<p className="art-description">"Something\'s Missing" - This concept is illustrated by two snakes which are affectionately inter-coiled in a desert atmosphere, yet something is unsettling about this union.\n' +
                'The subtle symbol of infinity (a figure 8) has been rendered in the design of which the snakes are interweaved.</p>' +
                '\n' +
                '<p>The humanesque expression on each of the serpent\'s faces reveals a sad quality of morose and discontentment; the connection is being severed. The desert sky is filled with shades of dreary grays and blues to exemplify a somber mood. The two poor creatures have weathered the storm, rain or shine, as it shows in their tattered and somewhat flayed skin. The difficulty to shed this skin is apparent.</p>' +
                '\n' +
                '<p>The eggs have hatched, and the young offspring has left the nest. All that surround these sullen reptiles, are rocks and desolation. Although they have remained intertwined, the male (right) reluctantly erects himself from the coil, as this suggests departure. The female (left) sheds a solemn tear, and appears to remain coiled in the nest.</p>',
            name: 'Something\'s Missing.JPG'
        },
        {
            title: 'The Magic of Flowers',
            img: img3,
            description: '<div className="art-description">A celebration of the beauty and wonder of flowers, capturing their essence in a magical way.</div>',
            name: 'The Magic of Flowers.JPG'
        },
        {
            title: 'We Reap What We Sow',
            img: img4,
            description: '<p>Inspired by Vincent Van Gogh, Millet and the early American contemporaries, LORD TOPH creates his own rendition of these female laborers in the field.</p>\n' +
                '\n' +
                '<p>Despite their arduous chores, there appears to be a solemnity as they toil on a windy, cloudy day.</p>',
            name: 'We Reap What We Sow.JPG'
        },
        {
            title: 'Yoonsil – On Canvas In Paris',
            img: img5,
            description: '<p>This particular composition is one of the more sustained representational paintings of Lord Toph\'s muse, Yoonsil.</p>\n' +
                '\n' +
                '<p>Although it is executed in a more traditional style, it maintains a contemporary quality despite the nostalgic setting rendered in the piece. Dark umber and sepia tones have been layered to help illustrate the hardwood floors, and Jacobean-stained wooden book shelf in the background.</p>\n' +
                '\n' +
                '<p>Deep moss-like shades of green are used to visually translate the texture of the sheer drapes lining the wall behind the muse, and the sheets of canvas that she sits on in the foreground.</p>\n' +
                '\n' +
                '<p>Her skin is illuminated by low positioned lighting, and her expression is contemplative yet content. She sits waiting patiently for the artist to begin rendering the image of her sitting among the unprimed canvases. For this same image will adorn many canvases for future paintings.</p>',
            name: 'Yoonsil – On Canvas In Paris.JPG'
        }
    ], []);

    const nextArtwork = () => {
        setCurrentArtIndex((prevIndex) => (prevIndex + 1) % artworks.length);
    };

    const previousArtwork = () => {
        setCurrentArtIndex((prevIndex) => (prevIndex - 1 + artworks.length) % artworks.length);
    };

    const setArtwork = (index) => {
        setCurrentArtIndex(index);
    };

    return (
        <div className="artgallery">
            <button onClick={previousArtwork}>Previous</button>
            <button onClick={nextArtwork}>Next</button>
            <h2>{artworks[currentArtIndex].title}</h2>
            <div dangerouslySetInnerHTML={{ __html: artworks[currentArtIndex].description }} />
            <Zoom>
                <img className="artimage" src={artworks[currentArtIndex].img} alt={artworks[currentArtIndex].title} />
            </Zoom>
            <div className="thumbnail-navigation">
                {artworks.map((artwork, index) => (
                    <img
                        key={index}
                        src={artwork.img}
                        alt={artwork.title}
                        title={artwork.title}
                        className={`thumbnail ${index === currentArtIndex ? 'active' : ''}`}
                        onClick={() => setArtwork(index)}
                        style={{ cursor: 'pointer', width: '50px', height: '50px', margin: '5px', border: index === currentArtIndex ? '2px solid black' : 'none' }}
                    />
                ))}
            </div>
            <ManifestRetriever
                fileName={artworks[currentArtIndex].name}
                filePath={artworks[currentArtIndex].img}
            />
        </div>
    );
};

export default ArtGallery;
