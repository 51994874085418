// Footer.js
import React from 'react';
import SocialLinks from "./SocialLinksSection";
import Chatbot from "./Chatbot";

const Footer = () => {
    const handleSetFilterCriteria = (criteria) => {
        // You can handle filter criteria here if needed
    };

    return (
        <section>
            <div>
                <Chatbot setFilterCriteria={handleSetFilterCriteria} />
            </div>
            <div>
                <SocialLinks />
            </div>
            <div className={"copyright"}>
                Monté CrisToph Multimedia © 2024 All Rights Reserved
            </div>
        </section>
    );
};

export default Footer;
