// HeroSection.js
import React from 'react';



const NotFoundPage = () => (
    <div className={"content"}>
        <h1>Oops! Page Not Found</h1>
        <p>Sorry, we can't find the page you were looking for.</p>

    </div>
);


export default NotFoundPage;