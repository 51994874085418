// ThankYouPage.js
import React from 'react';
import hero from './img/Lord Toph Thank You Header.png'
const ThankYouPage = () => {
    return (
        <div className="content thank-you-page">
            <img src={hero} className={"hero-img"} alt={"Thank You"}/>

            <h2>Thank You for Your Purchase!</h2>
            <div>Your music tracks have been downloaded successfully. Enjoy!</div>
        </div>
    );
};

export default ThankYouPage;
