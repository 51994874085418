import React from 'react';
import HomePage from "./HomePage";
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';
import PrivacyPage from "./PrivacyPage";
import AboutPage from "./AboutPage";
import AboutBiographyPage from "./AboutBiographyPage";
import AboutDiscographyPage from "./AboutDiscographyPage";
import AboutLiteraryCatalogPage from "./AboutLiteraturePage";
import AboutRelatedActsPage from "./AboutRelatedActsPage";
import BlogPage from "./BlogPage";
import Footer from "./Footer";
import Navbar from "./Navbar";
import ContactUsPage from "./ContactUsPage";
import NotFoundPage from "./NotFoundPage";
import DynamicBlogPost from "./DynamicBlogPost";
import ServicesPage from "./ServicesPage";
import TandRPage from "./TandRPage";
import DynamicTandRPost from "./DynamicTandRPost";
import MusicPage from "./MusicPage";
import LiteraturePage from "./LiteraturePage";
import ArtPage from "./ArtPage";
import MediaPlayer from './MediaPlayer';
import DownloadPage from './DownloadPage';
import PsychedelicDreamPage from "./PsychedelicDreamPage";
import CheckoutPage from "./CheckoutPage";
import ThankYouPage from "./ThankYouPage"; // Add the ThankYouPage import

function App() {
    return (
        <Router>
            <div className={"App"}>
                <Navbar />
                <Routes>
                    <Route path={"/"} element={<HomePage />} />
                    <Route path={"about-lord-toph"} element={<AboutPage />} />
                    <Route path={"about-lord-toph/biography"} element={<AboutBiographyPage />} />
                    <Route path={"about-lord-toph/discography"} element={<AboutDiscographyPage />} />
                    <Route path={"about-lord-toph/literary-catalog"} element={<AboutLiteraryCatalogPage />} />
                    <Route path={"about-lord-toph/related-acts"} element={<AboutRelatedActsPage />} />
                    <Route path={"privacy-policy"} element={<PrivacyPage />} />
                    <Route path={"contact-us"} element={<ContactUsPage />} />
                    <Route path={"404.html"} element={<NotFoundPage />} />
                    <Route path="/blog/*" element={<BlogPage />} />
                    <Route path={"music"} element={<MusicPage />} />
                    <Route path={"art"} element={<ArtPage />} />
                    <Route path={"literature"} element={<LiteraturePage />} />
                    <Route path={"services"} element={<ServicesPage />} />
                    <Route path={"tandr/*"} element={<TandRPage />} />
                    <Route path={"/media-player"} element={<MediaPlayer />} />
                    <Route path={"/downloads"} element={<DownloadPage />} />
                    <Route path={"/blog/:id"} element={<DynamicBlogPost />} />
                    <Route path={"/blog/post/:urlkey"} element={<DynamicBlogPost />} />
                    <Route path={"/tandr/:id"} element={<DynamicTandRPost />} />
                    <Route path={"/tandr/post/:urlkey"} element={<DynamicTandRPost />} />
                    <Route path={"psychedelic-dream"} element={<PsychedelicDreamPage />} />
                    <Route path={"/checkout"} element={<CheckoutPage />} /> {/* Added the CheckoutPage route */}
                    <Route path={"/thank-you"} element={<ThankYouPage />} /> {/* Added the ThankYouPage route */}
                </Routes>
                <Footer />
            </div>
        </Router>
    );
}

export default App;
