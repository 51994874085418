import React from 'react';
import MediaPlayer from './MediaPlayer';

const MusicPage = () => {
    return (
        <div className="content">

            <h1>Lord Toph Music</h1>
            <p>Welcome to Lord Toph's vibrant world of sound, where innovation and emotion collide to create an electrifying musical experience. Dive into a kaleidoscope of genres, each pulsating with its own unique energy and rhythm, and discover the magic of Lord Toph's unparalleled vocal range and dynamic artistry.
            </p>
            <MediaPlayer />
        </div>
    );
};

export default MusicPage;
