import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import './CheckoutPage.css';
import hero from "./img/Lord Toph Thank You Header.png";

const CheckoutPage = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const cart = location.state?.cart || [];

    if (cart.length === 0) {
        navigate('/');
        return null;
    }

    const createOrder = (data, actions) => {
        return actions.order.create({
            purchase_units: [{
                amount: {
                    value: cart.reduce((total, item) => total + parseFloat(item.price), 0).toFixed(2)
                },
                description: cart.map(item => item.title).join(', ')
            }]
        });
    };

    const onApprove = (data, actions) => {
        return actions.order.capture().then(() => {
            const zip = new JSZip();
            const promises = cart.map(track => {
                return fetch(track.src)
                    .then(response => response.blob())
                    .then(blob => zip.file(track.name, blob));
            });

            Promise.all(promises).then(() => {
                zip.generateAsync({ type: 'blob' }).then(content => {
                    saveAs(content, 'music_tracks.zip');
                    // Navigate to the Thank You page
                    navigate('/thank-you');
                });
            });
        });
    };

    return (
        <div className="content">
            <img src={hero} className={"hero-img"} alt={"Thank You"}/>
            <div className={"checkout-page"}>
            <h2>Order Summary</h2>
            <ul className="cart-list">
                {cart.map((track, index) => (
                    <li key={index} className="cart-item">
                        {track.title} - ${track.price} USD
                    </li>
                ))}
            </ul>
            <h3>Total: ${cart.reduce((total, item) => total + parseFloat(item.price), 0).toFixed(2)} USD</h3>
            <div className="paypal-buttons-container">
                <PayPalScriptProvider options={{
                    "client-id": "AREM_SE6WLZ55z_VHhCNhXcU42GVIJZS1zzdTSfzBUFBBALe1r8UgDAfXHWI98K_DaAbVNI83oUMFg28",
                    components: "buttons",
                    "data-funding-sources": "paypal,venmo"
                }}>
                    <PayPalButtons
                        createOrder={createOrder}
                        onApprove={onApprove}
                    />
                </PayPalScriptProvider>
            </div>
            </div>
        </div>
    );
};

export default CheckoutPage;
