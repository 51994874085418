import React, { useRef, useEffect } from 'react';
import AudioControls from './AudioControls';
import './controlstyles.css';

const CustomAudioPlayer = ({ src, onEnded, duration, onPrev, onNext }) => {
    const audioRef = useRef(null);
    const timerRef = useRef(null);

    const handleStop = () => {
        if (audioRef.current) {
            audioRef.current.pause();
            audioRef.current.currentTime = 0;
            clearTimeout(timerRef.current);
        }
    };

    useEffect(() => {
        const audioElement = audioRef.current;

        const handleLoadedData = () => {
            if (audioElement) {
                audioElement.play().catch(error => console.error('Audio play error:', error));
                startTimer();
            }
        };

        const handlePlay = () => {
            if (audioElement) {
                startTimer();
            }
        };

        const startTimer = () => {
            if (audioElement) {
                clearTimeout(timerRef.current);
                timerRef.current = setTimeout(() => {
                    handleStop();
                    onEnded();
                }, duration);
            }
        };

        if (audioElement) {
            audioElement.addEventListener('loadeddata', handleLoadedData);
            audioElement.addEventListener('play', handlePlay);
            audioElement.src = src;

            // Check initial state
            if (!audioElement.paused) {
                audioElement.play().catch(error => console.error('Audio play error:', error));
            }
        }

        return () => {
            clearTimeout(timerRef.current);
            if (audioElement) {
                audioElement.removeEventListener('loadeddata', handleLoadedData);
                audioElement.removeEventListener('play', handlePlay);
            }
        };
    }, [src, onEnded, duration]);

    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <audio ref={audioRef} />
            <AudioControls
                onPlay={() => audioRef.current.play()}
                onStop={handleStop}
                onPrev={onPrev}
                onNext={onNext}
            />
        </div>
    );
};

export default CustomAudioPlayer;
