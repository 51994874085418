// AboutPage.js
import React from 'react';
import logo2 from "./img/Lord_Toph_art_logo1.3cc94531.png";
import ArtGallery from "./ArtGallery";

const ArtPage = () => (

    <div className="content">
        <div className={"art2 animate__animated animate__fadeIn"}></div>


        <h1>Lord Toph Art</h1>
        <p>Welcome to Lord Toph's gallery of visual wonders, where imagination knows no bounds and every brushstroke tells a story. Delve into a world of color, emotion and boundless creativity, as you explore Lord Toph's eclectic collection of artworks, grounded in the pioneering philosophy of Empathicism and infused with a profound reverence for the human experience.</p>
            <div><ArtGallery /></div>
        <img className="artlogo" src={logo2} alt={"Lord Toph Art Logo"} />
    </div>
);


export default ArtPage;