//blogPosts/blogPosts.js

const tandRPosts = [

    {
        id: 1,
        urlkey:'levankitsmarishvili',
        title: 'A message from a fellow musician',
        author: 'Levan Kitsmarishvili',
        date: 'May 1, 2024',
        description: 'A message about Lord Toph from a fellow musician.',
        component: () => import('./TandRPost1'), // Assuming BlogPost1.js returns a default React component
    },
    {
        id: 3,
        urlkey:'mytragrant',
        title: 'A mentoring blurb.',
        author: 'Mytra Grant',
        date: 'May 1, 2024',
        description: 'A Mentoring blurb about Lord Toph from Mytra Grant aka "Stuck on Tell."',
        component: () => import('./TandRPost3'), // Assuming BlogPost1.js returns a default React component
    },
    {
        id: 4,
        urlkey:'damensamuel',
        title: 'Lord Toph Testimonial',
        author: 'Damen Samuel',
        date: 'May 1, 2024',
        description: 'An artist development blurb about Lord Toph from Damen Samuel',
        component: () => import('./TandRPost4'), // Assuming BlogPost1.js returns a default React component
    },

    {
        id: 8,
        urlkey:'gukelberger',
        title: 'Meeting Lord Toph.',
        author: 'Matthias Gukelberger',
        date: 'May 2, 2024',
        alt: 'Matthias Gukelberger happily receiving his shipment of newly purchased Lord Toph paintings (left), “The Conversation” and “Horse Race” by Lord Toph (right)',
        description: 'Meeting Lord Toph by Matthias Gukelberger.',
        component: () => import('./TandRPost8'), // Assuming BlogPost1.js returns a default React component
    },
    {
        id: 9,
        urlkey:'joonchoi',
        title: 'Lord Toph Recommendation.',
        author: 'Joon Choi',
        date: 'May 2, 2024',
        alt: 'Korean Business Consulting Firm AOG\'s CEO, Joon Choi',
        description: 'Recommending Lord Toph',
        component: () => import('./TandRPost9'), // Assuming BlogPost1.js returns a default React component
    },

];

export default tandRPosts;