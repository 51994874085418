import React, { useState, useEffect } from 'react';
import crlogo from './img/cr_logo.png';

const ManifestRetriever = ({ fileName, filePath, imageFilePath }) => {
    const [manifest, setManifest] = useState(null);
    const [imageManifest, setImageManifest] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState('https://paybots-claim-engine.azurewebsites.net/manifest');
    const [showManifest, setShowManifest] = useState(false);

    useEffect(() => {
        const fetchManifest = async (file, setter) => {
            setIsLoading(true);
            setError('');
            setter(null);

            try {
                const fileBlob = await fetch(file.path).then(res => res.blob());
                const fileObj = new File([fileBlob], file.name, { type: file.type });

                const formData = new FormData();
                formData.append('file', fileObj);

                const manifestResponse = await fetch('https://paybots-claim-engine.azurewebsites.net/api/manifest', {
                    method: 'POST',
                    body: formData,
                });

                if (manifestResponse.status === 500) {
                    throw new Error("Content Credentials Not Found");
                }

                if (!manifestResponse.ok) {
                    throw new Error(`Manifest retrieval failed with status: ${manifestResponse.status}`);
                }

                const responseData = await manifestResponse.json();
                setter(responseData);
            } catch (error) {
                console.error('Error retrieving manifest:', error);
                setError(`Error: ${error.message}`);
            } finally {
                setIsLoading(false);
            }
        };

        if (fileName && filePath) {
            fetchManifest({ name: fileName, path: filePath, type: 'audio/mp3' }, setManifest);
        }

        if (imageFilePath) {
            fetchManifest({ name: imageFilePath.split('/').pop(), path: imageFilePath, type: 'image/jpeg' }, setImageManifest);
        }
    }, [fileName, filePath, imageFilePath]);

    const handleToggleManifest = () => {
        setShowManifest(!showManifest);
    };

    return (
        <div className="inspectFile">
            <div className="clog" onClick={handleToggleManifest}>
                <img src={crlogo} className="crlogo" title="Show/Hide Content Credentials" alt="Show/Hide Content Credentials" />
            </div>
            {isLoading && <p>Getting Content Credentials...</p>}
            {showManifest && (
                <div className="manifest">
                    {error && <p className="error">{error}</p>}
                    {manifest && (
                        <>
                            <pre>{JSON.stringify(manifest, null, 2)}</pre>
                        </>
                    )}
                    {imageManifest && (
                        <>
                            <pre>{JSON.stringify(imageManifest, null, 2)}</pre>
                        </>
                    )}
                    <div className={"claimedat"}>What's the <img src={crlogo} className={"cr2"} alt="cr" />? It shows you can check authenticity. Claim your files at <a href={"https://file.baby"} alt={"That's My File, Baby!"} title={"That's My File, Baby!"}> File Baby</a>..."That's My File, Baby!" using <a href={"https://c2pa.org"}>C2PA</a>-based <a href={"https://contentcredentials.org/verify"}>Content Credentials</a></div>
                </div>
            )}
        </div>
    );
};

export default ManifestRetriever;
