import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './Chatbot.css';
import chatConfig from './ChatSetupSmueth.json';

const searchServiceName = "smuth";
const indexName = "azureblob-index";
const searchApiKey = process.env.REACT_APP_AZURE_SEARCH_API_KEY;
const searchEndpoint = `https://${searchServiceName}.search.windows.net`;

const Chatbot = ({ setFilterCriteria }) => {
    const [prompt, setPrompt] = useState('');
    const [response, setResponse] = useState([]);
    const [isOpen, setIsOpen] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const responseEndRef = useRef(null);
    const chatPopupRef = useRef(null);

    const handleInputChange = (e) => {
        setPrompt(e.target.value);
    };

    const interpretAndActOnGPTResponse = (gptResponse) => {
        try {
            const lowerCaseResponse = gptResponse.toLowerCase();
            if (lowerCaseResponse.includes("image") || lowerCaseResponse.includes("photo")) {
                setFilterCriteria({ type: 'image' });
            } else if (lowerCaseResponse.includes("audio") || lowerCaseResponse.includes("music")) {
                setFilterCriteria({ type: 'audio' });
            } else if (lowerCaseResponse.includes("document")) {
                setFilterCriteria({ type: 'document' });
            } else if (lowerCaseResponse.includes("video")) {
                setFilterCriteria({ type: 'video' });
            } else {
                setFilterCriteria({}); // Clear filter criteria
            }
        } catch (error) {
            console.error('Error in interpretAndActOnGPTResponse:', error);
            alert(`Error: ${error.message}`);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (!prompt.trim()) return;

        setIsLoading(true);

        // Step 1: Query Azure Search
        let searchResults = '';
        try {
            const searchParams = new URLSearchParams({
                'api-version': '2020-06-30',
                'search': prompt,
                '$top': 5
            });

            const searchResponse = await axios.get(`${searchEndpoint}/indexes/${indexName}/docs?${searchParams}`, {
                headers: {
                    'api-key': searchApiKey,
                    'Content-Type': 'application/json'
                }
            });

            if (searchResponse.status !== 200) {
                throw new Error(`Azure Search error: ${searchResponse.statusText}`);
            }

            searchResponse.data.value.forEach(result => {
                searchResults += result.content + ' ';
            });
        } catch (error) {
            console.error('Error querying Azure Search:', error);
            alert(`Error querying Azure Search: ${error.message}`);
            setIsLoading(false);
            return;
        }

        // Step 2: Use Search Results to Enhance OpenAI Request
        const apiEndpoint = "https://smvethai.openai.azure.com/openai/deployments/Smveth/chat/completions?api-version=2023-03-15-preview";

        const headers = {
            'api-key': process.env.REACT_APP_OPENAI_API_KEY,
            'Content-Type': 'application/json'
        };

        const data = {
            model: "gpt-4o",
            messages: [
                { role: "system", content: "You are Smueth, and you oversee household upkeep and hired maintenance staff, acts often as Lord Toph’s amanuensis and creative advisor. You have taken special effort at helping curious visitors to lordtoph.com find information about his work, especially in music, art, and literature. You are happy to help people locate things that are on the website at https://lordtoph.com." },
                { role: "user", content: prompt },
                { role: "assistant", content: searchResults }  // Include search results as context
            ],
            max_tokens: chatConfig.chatParameters.maxResponseLength,
            temperature: chatConfig.chatParameters.temperature,
            top_p: chatConfig.chatParameters.top_p,
            frequency_penalty: chatConfig.chatParameters.frequencyPenalty,
            presence_penalty: chatConfig.chatParameters.presencePenalty,
        };

        try {
            console.log('Sending request to API:', apiEndpoint);
            console.log('Request headers:', headers);
            console.log('Request data:', JSON.stringify(data, null, 2));

            const apiResponse = await axios.post(apiEndpoint, data, { headers });

            console.log('API Response:', apiResponse.data); // Log the full response for debugging
            const gptResponse = apiResponse.data.choices[0].message.content;
            setResponse(prevResponses => [...prevResponses, { question: prompt, answer: gptResponse }]);
            interpretAndActOnGPTResponse(gptResponse);
        } catch (error) {
            console.error('Error with OpenAI Chat:', error.response ? error.response.data : error.message);
            alert(`Error: ${error.response ? JSON.stringify(error.response.data) : error.message}`);
        }

        setIsLoading(false);
        setPrompt(''); // Clear the input field
    };

    const handleClearChat = () => {
        setResponse([]);
        setPrompt(''); // Clear the input field
    };

    const handleCopyChat = () => {
        const chatContent = response.map(exchange => `You: ${exchange.question}\nSmueth: ${exchange.answer}`).join('\n\n');
        navigator.clipboard.writeText(chatContent).then(() => {
            alert('Chat copied to clipboard');
        });
    };

    useEffect(() => {
        responseEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    }, [response]);

    const toggleChat = () => {
        setIsOpen(!isOpen);
    };

    useEffect(() => {
        const chatContainer = document.querySelector('.chat-container');
        if (isOpen && window.innerWidth >= 768) {
            chatContainer.classList.add('expanded');
        } else {
            chatContainer.classList.remove('expanded');
        }
    }, [isOpen]);

    return (
        <div className="chat-container">
            <button className="chat-toggle-button" onClick={toggleChat}>
                {isOpen ? 'Hide Ask Smueth' : 'Questions? Ask Smueth'}
            </button>
            {isOpen && (
                <div className="chat-popup" ref={chatPopupRef}>
                    <div className="chat-title-bar">Ask Smueth</div>
                    <div className={`loading-overlay ${isLoading ? 'visible' : ''}`}>
                        <div className="loading-indicator">Generating Response _</div>
                    </div>

                    <div className="response-container">
                        {response.map((exchange, index) => (
                            <div className="chat" key={index}>
                                <div className="user"><strong>You:</strong> {exchange.question}</div>
                                <div className="filebaby">
                                    <strong>Smueth:</strong>
                                    {exchange.answer.split('\n').map((paragraph, i) => (
                                        <p key={i}>{paragraph}</p>
                                    ))}
                                </div>
                            </div>
                        ))}
                        <div ref={responseEndRef} />
                    </div>
                    <form onSubmit={handleSubmit}>
                        <input
                            type="text"
                            value={prompt}
                            onChange={handleInputChange}
                            placeholder="What would you like to know about Lord Toph?"
                        />
                        <div className="button-container">
                            <button tabIndex="0" type="submit" title="Send to Smueth">Send</button>
                            <button type="button" onClick={handleClearChat} title="Clear Chat">Clear</button>
                            <button type="button" onClick={handleCopyChat} title="Copy Chat">Copy</button>
                        </div>
                    </form>
                </div>
            )}
        </div>
    );
};

export default Chatbot;
