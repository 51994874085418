//./blog_posts/BlogList.js

import {Link} from "react-router-dom";
import Hero from '../img/lord_toph_a_peaceful_memory_background.png'
import React from "react";
import TandRPosts from "../TandRPosts/TandRPosts";

const TandRList = () => (
    <div className={"content"}>
        <img className={"hero-img animate__animated animate__fadeIn"} src={Hero} alt={"Lord Toph's Desk"} />
        <h1 className={"tandrlist"}>Testimonials & Recommendations</h1>
        {TandRPosts.map(post => (
            <div key={post.id} >
                <div className={'tandr-summary'}>
                <div class={"tandrlist"}>{post.description}                     <div className={"tandrlist"}>By {post.author} on {post.date}</div>
<Link to={`/tandr/post/${post.urlkey}`}>read more</Link></div>

                </div>

            </div>
        ))}
        <div className={"parallax"}></div>

    </div>
);
export default TandRList;