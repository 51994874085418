import React from 'react';
import { useLocation } from 'react-router-dom';

// Import all audio files here
import audio0 from './music/Close To You.mp3';
import audio1 from './music/China Girl ft. SnowFlake Black.mp3';
import audio2 from './music/Incantations of Melody (An Autumn Song).mp3';
import audio4 from './music/1716006618638-Beneath the Falls.mp3';
import audio5 from './music/Foolish Games.mp3';
import audio6 from './music/The Other Side of the Mountain.mp3';
import audio7 from './music/All That I Waited, More (기다린 만큼, 더).mp3';
import audio8 from './music/My Army.mp3';
import audio9 from './music/Not To Worry - Master.mp3';
import audio10 from './music/Said and Done.mp3';
import audio11 from './music/The 80s Are Gone (Basic Pro Master 128kbps).mp3';
import audio12 from './music/Wanna Love Ya.mp3';

const DownloadPage = () => {
    const query = new URLSearchParams(useLocation().search);
    const songName = query.get('custom'); // Check the 'custom' parameter

    const getSongUrlByName = (songName) => {
        const songDatabase = {
            'file.baby@knowbots.org_1715998422884-LORD TOPH - Close To You.mp3': audio0,
            'Wanna_Love_Ya.mp3': audio12,
            'file.baby@knowbots.org_1715999040546-The Other Side of the Mountain.mp3': audio6,
            'file.baby@knowbots.org_1716005338525-China Girl ft. SnowFlake Black.mp3': audio1,
            'file.baby@knowbots.org_1716004939549-14. Incantations of Melody (An Autumn Song).mp3': audio2,
            'Beneath the Falls.mp3': audio4,
            'Foolish Games.mp3': audio5,
            'All_That_I_Waited_More.mp3': audio7,
            'My_Army.mp3': audio8,
            'Not_To_Worry.mp3': audio9,
            'Said_and_Done.mp3': audio10,
            'The_80s_Are_Gone.mp3': audio11,
        };
        return songDatabase[songName] || '#';
    };

    const songUrl = getSongUrlByName(songName);

    return (
        <div className={"content"}>
            {songName ? (
                <div>
                    <p>Thank you for your purchase!</p>
                    <a href={songUrl} download>Download your song here</a>
                </div>
            ) : (
                <p>No song purchased.</p>
            )}
        </div>
    );
};

export default DownloadPage;
