// HeroSection.js
import React from 'react';
import Hero from "./img/LORD TOPH_by_Pieter_Henket.png"



const handleClick = () => {
    window.location.href = "../"
};
const handleClick2 = () => {
    window.location.href = "../"
};
const handleClick3 = () => {
    window.location.href = "../"
};
const HeroSection1 = () => (
    <div className={"contentauth"}>

        <div><img src={Hero} className={"hero-img hero-img animate__animated animate__fadeInDownBig"} alt="LORD TOPH by Pieter Henket" /></div>
        <div className={"animate__animated animate__faster"}><h1>THROUGH THE EYES OF AN EMPATH</h1>
                <h2>Lord Toph's music, art, literature, multimedia, spoken word, acting ... so many adventures await you.</h2>
            <div>Lord Toph hails from the shadows of New York City, emerging from the night with music, art and books to tantalize your mind and awaken your senses like never before.</div></div>
<br /><br />
        <div className={"parallax"}></div>
        <div onClick={handleClick3} className={"music"}>
            <h1>Lord Toph Music</h1>
            Lord Toph's musical journey takes you from pure joy to total relaxation. </div>
          <div className={"parallax"}></div>
            <div onClick={handleClick2} className ={"art"}>
                <h1>Lord Toph Art</h1>
                Lord Toph creates original artwork–and curates work of others – that wrap you in a world of Empathatism–bringing you a deep connection with human introspection and reflection.
            </div>
                <div className={"parallax"}></div>
        <div onClick={handleClick} className ={"books"}>
            <h1>Lord Toph Literature</h1>
            From adult-oriented thought-provoking novels and poetry collections like "Noctvrnal Passages," to whimsical children's adventures in his StarField Stories world – Lord Toph spins tales for all ages.
        </div>
        <div className={"parallax"}></div>

    </div>

);


export default HeroSection1;



