//./blog_posts/BlogList.js
import blogPosts from './blogPosts';
import {Link} from "react-router-dom";
import Hero from '../img/Wine_and_Writing_Books.png'
import React from "react";

const BlogList = () => (
    <div className={"content blog"}>
        <img className={"hero-img hero-img animate__animated animate__fadeIn"} src={Hero} alt={"Lord Toph at his Writing Desk"} />
        <h1>Blog</h1>
        {blogPosts.map(post => (
            <div key={post.id} className="blog-summary">
                <span className={'blog=summary'}>
                <Link to={`/blog/post/${post.urlkey}`}><img className="herosmall filebaby" src={post.image} alt={"hero"} /></Link>
                <h2><Link to={`/blog/post/${post.urlkey}`}>{post.title}</Link></h2>
                <p>By {post.author} on {post.date}</p>
                <p>{post.description} <Link to={`/blog/post/${post.urlkey}`}>read more</Link></p>
                </span>
            </div>
        ))}
        <div className={"parallax"}></div>

    </div>
);
export default BlogList;