import React from 'react';
import { FaPlay, FaStop, FaStepBackward, FaStepForward } from 'react-icons/fa';
import './controlstyles.css';

const AudioControls = React.forwardRef(({ onPlay, onStop, onPrev, onNext }, ref) => (
    <div className="audio-controls">
        <button onClick={onPrev} className="audio-button">
            <FaStepBackward />
        </button>
        <button onClick={onPlay} className="audio-button">
            <FaPlay />
        </button>
        <button onClick={onStop} className="audio-button">
            <FaStop />
        </button>
        <button onClick={onNext} className="audio-button">
            <FaStepForward />
        </button>
    </div>
));

export default AudioControls;
