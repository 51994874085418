import React, { Suspense, lazy } from 'react';
import { useParams } from 'react-router-dom';
import tandRPosts from "./TandRPosts/TandRPosts";

const DynamicTandRPost = () => {
    const { urlkey } = useParams();
    const postEntry = tandRPosts.find(post => post.urlkey.toString() === urlkey);

    console.log(postEntry); // Check what you get here

    if (!postEntry) return <div>Post not found!</div>;

    const PostComponent = lazy(postEntry.component);

    return (
        <Suspense fallback={<div>Loading...</div>}>
            <PostComponent />
        </Suspense>
    );
};


export default DynamicTandRPost;
