
import { Suspense } from 'react';
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import TandRList from './TandRPosts/TandRList'; // Make sure this import is correct
import DynamicTandRPost from './DynamicTandRPost'; // Make sure this import is correct

const TandRPage = () => {
    return (
        // Suspense wraps Routes to catch any lazy loading issues
        <Suspense fallback={<div>Loading...</div>}>
            <Routes>
                <Route index element={<TandRList />} />
                <Route path=":urlkey" element={<DynamicTandRPost />} />
            </Routes>
        </Suspense>
    );
};
export default TandRPage;
