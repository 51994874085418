import React, { useState, useMemo, useEffect } from 'react';
import Zoom from 'react-medium-image-zoom';
import 'react-medium-image-zoom/dist/styles.css';
import img1 from './books/Concise_Candid_Confessions_of_the_Grisly_Grotesque_by_Lord_Toph.jpg';
import img2 from './books/Dear_Miss_Anne_Marie_by_Lord_Toph.jpg';
import img3 from './books/Merthodokalis_Monk_and_the_Haunted_Hat.jpg';
import img4 from './books/Our_Little_Red_Wagon_by_Lord_Toph.jpg';
import img5 from './books/Pretty_Little_Black_Girl_by_Lord_Toph.jpg';
import img6 from './books/Sayings_Quotes_and_Phrases_by_Lord_Toph.jpg';
import img7 from './books/The_Spinster_of_Contempt_by_Lord_Toph.jpg';
import ManifestRetriever from './ManifestRetriever';

const LiteratureGallery = () => {
    const [currentArtIndex, setCurrentArtIndex] = useState(0);

    useEffect(() => {
        // Apply custom class to the overlay when it appears
        const observer = new MutationObserver(mutations => {
            mutations.forEach(mutation => {
                if (mutation.type === 'childList') {
                    const overlay = document.querySelector('.data-rmiz-overlay');
                    if (overlay) {
                        overlay.classList.add('black-overlay');
                    }
                }
            });
        });

        observer.observe(document.body, { childList: true, subtree: true });

        // Clean up observer on component unmount
        return () => observer.disconnect();
    }, []);

    const artworks = useMemo(() => [
        {
            title: 'Concise Candid Confessions of the Grisly Grotesque',
            img: img1,
            description: '<div className="art-description">Whether one is considered physically attractive or unattractive, we all know that in the end, it is all relative.</div>',
            product: 'Product details\n' +
                'ASIN ‏ : ‎ B0C9S86RD4\n' +
                'Publisher ‏ : ‎ Independently published (June 30, 2023)\n' +
                'Language ‏ : ‎ English\n' +
                'Paperback ‏ : ‎ 155 pages\n' +
                'ISBN-13 ‏ : ‎ 979-8396085664\n' +
                'Item Weight ‏ : ‎ 1.04 ounces\n' +
                'Dimensions ‏ : ‎ 8.5 x 0.35 x 11 inches',
            name: 'Concise_Candid_Confessions_of_the_Grisly_Grotesque_by_Lord_Toph.jpg'
        },
        {
            title: 'Dear Miss Anne Marie',
            img: img2,
            description: '<div className="art-description">“Dear Miss Anne Marie,” is from a collection of thirty-five poetic works entitled, “Nocturnal Passages.”</div>',
            product: 'Product details\n' +
                'ASIN ‏ : ‎ B0C6BM4H26\n' +
                'Publisher ‏ : ‎ Independently published (May 29, 2023)\n' +
                'Language ‏ : ‎ English\n' +
                'Paperback ‏ : ‎ 34 pages\n' +
                'ISBN-13 ‏ : ‎ 979-8395098979\n' +
                'Item Weight ‏ : ‎ 2.52 ounces\n' +
                'Dimensions ‏ : ‎ 6 x 0.09 x 9 inches',
            name: 'Dear_Miss_Anne_Marie_by_Lord_Toph.jpg'
        },
        {
            title: 'Our Little Red Wagon',
            img: img4,
            description: '<div className="art-description">Prepare to be captivated by the luminous magic that only family can create, as "Our Little Red Wagon" takes you on a journey through time. </div>',
            product: 'Product details\n' +
                'ASIN ‏ : ‎ B0CK3ZHCPC\n' +
                'Publisher ‏ : ‎ Independently published (September 28, 2023)\n' +
                'Language ‏ : ‎ English\n' +
                'Paperback ‏ : ‎ 115 pages\n' +
                'ISBN-13 ‏ : ‎ 979-8862047417\n' +
                'Reading Age ‏ : ‎ 12 - 18 years\n' +
                'Item Weight ‏ : ‎ 8.2 ounces\n' +
                'Dimensions ‏ : ‎ 6 x 0.45 x 9 inches',
            name: 'Our_Little_Red_Wagon_by_Lord_Toph.jpg'
        },
        {
            title: 'Merthodokalis Monk and the Haunted Hat',
            img: img3,
            description: '<div className="art-description">Mur-thod-uh-kolis is how you say it. And yes, that is really his name. </div>',
            product: 'Product details\n' +
                'ASIN ‏ : ‎ B0CVL4B85D\n' +
                'Publisher ‏ : ‎ Independently published (February 14, 2024)\n' +
                'Language ‏ : ‎ English\n' +
                'Paperback ‏ : ‎ 60 pages\n' +
                'Reading Age ‏ : ‎ 3-12 years\n' +
                'ISBN-13 ‏ : ‎ 979-8878261685\n' +
                'Item Weight ‏ : ‎ 6.1 ounces\n' +
                'Dimensions ‏ : ‎ 8.5 x 0.15 x 8.5 inches',
            name: 'Merthodokalis_Monk_and_the_Haunted_Hat.jpg'
        },
        {
            title: 'Pretty Little Black Girl',
            img: img5,
            description: '<div className="art-description">Lord Toph unveils the unwavering spirit of women of color, specifically the black woman. </div>',
            product: 'Product details\n' +
                'ASIN ‏ : ‎ B0CVQ7JDMR\n' +
                'Publisher ‏ : ‎ Independently published (February 14, 2024)\n' +
                'Language ‏ : ‎ English\n' +
                'Paperback ‏ : ‎ 26 pages\n' +
                'ISBN-13 ‏ : ‎ 979-8879603453\n' +
                'Item Weight ‏ : ‎ 2.72 ounces\n' +
                'Dimensions ‏ : ‎ 5 x 0.07 x 8 inches',
            name: 'Pretty_Little_Black_Girl_by_Lord_Toph.jpg'
        },
        {
            title: 'Sayings, Quotes and Phrases',
            img: img6,
            description: '<div className="art-description">The meaning or message behind each clever saying, quote or phrase, is sure to resonate with the reader in a wonderfully positive way.</div>',
            product: 'Product details\n' +
                'ASIN ‏ : ‎ B0C2S71D7F\n' +
                'Publisher ‏ : ‎ Independently published (April 26, 2023)\n' +
                'Language ‏ : ‎ English\n' +
                'Paperback ‏ : ‎ 252 pages\n' +
                'ISBN-13 ‏ : ‎ 979-8391954569\n' +
                'Item Weight ‏ : ‎ 1.5 ounces\n' +
                'Dimensions ‏ : ‎ 8.5 x 0.53 x 11 inches',
            name: 'Sayings_Quotes_and_Phrases_by_Lord_Toph.jpg'
        },
        {
            title: 'The Spinster of Contempt',
            img: img7,
            description: '<div className="art-description">A tale about the Spinster that blends the suspense of a classic thriller with the eerie subtleties of Gothic literature.</div>',
            product: 'Product details\n' +
                'ASIN ‏ : ‎ B0CLP996LJ\n' +
                'Publisher ‏ : ‎ Independently published (October 21, 2023)\n' +
                'Language ‏ : ‎ English\n' +
                'Paperback ‏ : ‎ 26 pages\n' +
                'ISBN-13 ‏ : ‎ 979-8862128949\n' +
                'Reading Age ‏ : ‎ 17 - 18 years\n' +
                'Item Weight ‏ : ‎ 2.72 ounces\n' +
                'Dimensions ‏ : ‎ 5 x 0.06 x 8 inches',
            name: 'The_Spinster_of_Contempt_by_Lord_Toph.jpg'
        }
    ], []);

    const nextArtwork = () => {
        setCurrentArtIndex((prevIndex) => (prevIndex + 1) % artworks.length);
    };

    const previousArtwork = () => {
        setCurrentArtIndex((prevIndex) => (prevIndex - 1 + artworks.length) % artworks.length);
    };

    const setArtwork = (index) => {
        setCurrentArtIndex(index);
    };

    return (
        <div className="artgallery">
            <button onClick={previousArtwork}>Previous</button>
            <button onClick={nextArtwork}>Next</button>
            <h2>{artworks[currentArtIndex].title}</h2>
            <div dangerouslySetInnerHTML={{ __html: artworks[currentArtIndex].description }} />
            <div>{artworks[currentArtIndex].product}</div>
            <Zoom>
                <img className="literatureimage" src={artworks[currentArtIndex].img} alt={artworks[currentArtIndex].title} />
            </Zoom>
            <div className="thumbnail-navigation">
                {artworks.map((artwork, index) => (
                    <img
                        key={index}
                        src={artwork.img}
                        alt={artwork.title}
                        title={artwork.title}
                        className={`thumbnail ${index === currentArtIndex ? 'active' : ''}`}
                        onClick={() => setArtwork(index)}
                        style={{ cursor: 'pointer', width: '50px', height: '50px', margin: '5px', border: index === currentArtIndex ? '2px solid black' : 'none' }}
                    />
                ))}
            </div>
            <ManifestRetriever
                fileName={artworks[currentArtIndex].name}
                filePath={artworks[currentArtIndex].img}
            />
        </div>
    );
};

export default LiteratureGallery;
